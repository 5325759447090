import {Component, Injector, Input, OnInit} from '@angular/core';
import {PreviewComponent} from '../../../preview/preview.component';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Component({
  selector: 'app-preview-soporte',
  templateUrl: './preview-soporte.component.html',
  styleUrls: ['./preview-soporte.component.scss']
})
export class PreviewSoporteComponent extends PreviewComponent {

  protected _rutaWordpress: string;
  protected formularioSoporteVisible: boolean = false;
  private lastSanitizedUrl: SafeResourceUrl | null = null;

  @Input()
  public set rutaWordpress(value: string) {
    if (this._rutaWordpress !== value) {
      this._rutaWordpress = value;
      this.lastSanitizedUrl = this.sanitizeUrl(value);
    }
  }

  constructor(private sanitizer: DomSanitizer) {
    super();
  }

  public sanitizeUrl(_rutaWordpress: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(_rutaWordpress);
  }

  public get sanitizedUrl(): SafeResourceUrl | null {
    return this.lastSanitizedUrl;
  }

  public toggleFormularioSoporte(): void {
    this.formularioSoporteVisible = !this.formularioSoporteVisible;
  }

  public calcularEstilosIframe(): string {
    const preview = document.getElementsByClassName('panel-wrap sm');
    if (preview.length > 0) {
      const altoBotonContacto = 50;
      const altoHeaderPreview = 83;
      const previewHeight = preview[0].clientHeight - altoHeaderPreview - altoBotonContacto;
      return `height: ${previewHeight}px; width: 100%; padding-bottom: 0.2rem;`;
    }
  }
}
