<div class="modal-header-primary pb-3">
  <h3 class="modal-title">{{ tituloParteActual | translate }}</h3>
  <button (click)="dismiss()" aria-label="Close" class="btn-close btn-close-white" data-dismiss="modal" type="button">

  </button>
</div>
<div class="modal-body p-4">
  <ng-container *ngIf="primeraParte">
    <div class="font-weight-500 text-18">{{ 'Licencia básica' | translate }}</div>
    <h2 class="titulo-precio m-0 mb-1 mt-1">2€/{{ 'mes' | translate }}</h2>
    <div class="text-muted">{{ 'Precio por usuario activo. Facturación anual. IVA no incluido' | translate }}</div>
    <hr>
    <div class="text-18 font-weight-500">{{ 'Incluye' | translate }}:</div>
    <ul class="list-unstyled mt-1">
      <li class="mb-1">{{ 'Acceso a todas la funcionalidades según permisos de usuario' | translate }}</li>
      <li class="mb-1">{{ '15 GB de almacenamiento' | translate }}</li>
      <li class="mb-1">{{ 'Soporte vía email' | translate }}</li>
      <li class="mb-1">{{ 'Acceso centro de ayuda' | translate }}</li>
    </ul>
  </ng-container>

  <ng-container *ngIf="segundaParte">
    <app-formulario-informacion-facturacion #formularioInformacionFacturacion
                                            [mostrarErroresCabecera]="true">
    </app-formulario-informacion-facturacion>
  </ng-container>

  <ng-container *ngIf="terceraParte">
    <div class="border-bottom pb-3">
      <div class="font-weight-500 text-18">{{ 'Tu compra' | translate }}</div>
      <ul class="list-group mb-3 mt-2">
        <li class="list-group-item d-flex justify-content-between align-items-center lh-sm">
          <div class="py-1">
            <h3 class="my-0">{{ 'Licencia básica x' + informacionLicencia.numero_usuarios_asignados }}</h3>
            <small
              class="text-body-secondary">{{ 'Usuarios activos' }}</small>
          </div>
          <span class="text-body-secondary">{{ importeBruto | currency :'EUR':true }}</span>
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-center lh-sm">
          <div class="py-1">
            <h3 class="my-0">{{ 'Impuestos' }}</h3>
            <small class="text-body-secondary">{{ '21% IVA' }}</small>
          </div>
          <span
            class="text-body-secondary">{{ informacionLicencia.numero_usuarios_asignados * 2 * 0.21 * 12 | currency :'EUR':true }}</span>
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-center">
          <div class="py-1">
            <span>Total (EUR)</span>
          </div>
          <strong>{{ importeTotalPagar | currency :'EUR':true }}</strong>
        </li>
      </ul>
    </div>
    <app-metodo-de-pago #metodoDePago (licenciaActualizada)="pagoRealizado()" class="mt-2"
                        [mostrarBotonActualizar]="false"></app-metodo-de-pago>
  </ng-container>
  <ng-container *ngIf="cuartaParte">
    <div class="font-weight-500 text-18 mb-2">{{ 'Tu compra' | translate }}</div>
    <ul class="list-group mb-3">
      <li class="list-group-item d-flex justify-content-between align-items-center lh-sm">
        <div class="py-1">
          <h3 class="my-0">{{ 'Número de licencias' }}</h3>
        </div>
        <span class="">{{ informacionLicencia.numero_usuarios_asignados }}</span>
      </li>
      <li class="list-group-item d-flex justify-content-between align-items-center lh-sm">
        <div class="py-1">
          <h3 class="my-0">{{ 'Precio anual por licencia' }}</h3>
          <small
            class="text-body-secondary">{{ 'IVA incluido' | translate }}</small>
        </div>
        <span
          class="">{{ importeTotalPagar / informacionLicencia.numero_usuarios_asignados | currency :'EUR':true }}</span>
      </li>
      <li class="list-group-item d-flex justify-content-between align-items-center lh-sm">
        <div class="py-1">
          <h3 class="my-0">{{ 'Total importe' }}</h3>
        </div>
        <span class="">{{ importeTotalPagar | currency :'EUR':true }}</span>
      </li>
      <li class="list-group-item d-flex justify-content-between align-items-center lh-sm">
        <div class="py-1">
          <h3 class="my-0">{{ 'Próxima renovación*' }}</h3>
        </div>
        <span
          class="">{{ informacionLicencia.fecha_vencimiento_licencia | date:'dd/MM/yyyy' }}</span>
      </li>
    </ul>
    <div class="mt-2">
      <small
        class="text-muted">{{ '*El cobro de tu próxima renovación se realizará de forma automática una vez haya caducado tu licencia de prueba.' }}</small>
    </div>
  </ng-container>
</div>
<div class="modal-footer border-top-0 px-4 pt-0 pb-4">
  @if (primeraParte) {
    <button (click)="navegaFacturacion()" class="boton-modal-primary">
      {{ 'Contratar' | translate }}
    </button>
    <button (click)="dismiss()" class="boton-modal-cancelar">
      {{ 'Cancelar' | translate }}
    </button>
  }

  @if (segundaParte) {
    <button (click)="navegaTarjeta()" class="boton-modal-primary"
            type="button">{{ 'Guardar' | translate }}
    </button>
    <button (click)="volverPrimeraParte()" class="boton-modal-cancelar"
            type="button">{{ 'Volver' | translate }}
    </button>
  }

  @if (terceraParte) {
    <button (click)="pagar()" class="boton-modal-primary"
            type="button">{{ 'Añadir método pago' | translate }}
    </button>
    <button (click)="volverPrimeraSegundaParte()" class="boton-modal-cancelar"
            type="button">{{ 'Volver' | translate }}
    </button>
  }

  @if (cuartaParte) {
    <button (click)="finalizar()" class="boton-modal-primary"
            type="button">{{ 'Finalizar' | translate }}
    </button>
  }
</div>


