import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-preview',
  template: '',
  styleUrls: ['./preview.component.scss']
})
export class PreviewComponent {
  @Input() elementoSeleccionado: any;
  private resolverPromise;
  private rejectPromise;
  public isPanelVisible: boolean = false;
  private closeDissmissPromise = new Promise((resolve, reject) => {
    this.resolverPromise = resolve;
    this.rejectPromise = reject;
  });
  @Output() public panelCerrado: EventEmitter<boolean> = new EventEmitter<boolean>();

  public cerrarPanel(panelId: string): void {
    this.ocultarPanel(panelId);
    this.resolverPromise();
    this.closeDissmissPromise = new Promise((resolve, reject) => {
      this.resolverPromise = resolve;
      this.rejectPromise = reject;
    });
    this.panelCerrado.emit(true);
    this.isPanelVisible = false;
  }

  public ocultarPanel(panelId: string): void {
    const panel = document.getElementById(panelId) as HTMLElement;
    if (panel) {
      panel.style.transform = 'translateX(100%)';
    }
  }

  public rechazar(panelId: string): void {
    this.ocultarPanel(panelId);
    this.isPanelVisible = false;
    this.rejectPromise();
  }

  public open(panelId: string): Promise<any> {
    const panel = document.getElementById(panelId) as HTMLElement;
    this.isPanelVisible = true;
    if (panel) {
      panel.style.transform = 'translateX(0%)';
    }
    return this.closeDissmissPromise;
  }

  public cierraPanel(panelId: string) {
    this.cerrarPanel(panelId);
  }
}
