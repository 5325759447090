import {Component, Injector, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AbstractControl, FormBuilder, UntypedFormArray, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {ConfiguracionNotificacion} from '../../../../domain/configuracion-usuario/configuracion-notificacion.model';
import {ConfiguracionUsuarioService} from '../../../../domain/configuracion-usuario/configuracion-usuario.service';
import {InformacionTrabajador} from '../../../../shared/services/trabajador.model';
import {PreviewComponent} from '../../../preview/preview.component';

@Component({
  selector: 'app-preview-configuracion-notificaciones',
  templateUrl: './preview-configuracion-notificaciones.component.html',
  styleUrls: ['./preview-configuracion-notificaciones.component.scss']
})
export class PreviewConfiguracionNotificacionesComponent extends PreviewComponent implements OnInit {
  private _informacionTrabajador = {} as InformacionTrabajador;
  public configuracionNotificacionesForm = {} as UntypedFormGroup;
  private _usuarioUsaEmailValido: boolean;

  @Input()
  public set informacionTrabajador(value: InformacionTrabajador) {
    this._informacionTrabajador = value;
    this.getConfiguracionNotificacionesUsuario();
  }

  @Input()
  public set usuarioUsaEmailValido(value: boolean) {
    this._usuarioUsaEmailValido = value;
  }

  public configuracionNotificaciones = [] as ConfiguracionNotificacion[];

  constructor(private injector: Injector,
              private configuracionUsuarioService: ConfiguracionUsuarioService,
              private formBuilder: FormBuilder) {
    super(injector);
    this.buildForm();
  }

  public ngOnInit(): void {
  }

  public getConfiguracionNotificacionesUsuario() {
    this.getConfiguracionNotificacionesFormArray().clear();
    this.configuracionUsuarioService.getConfiguracionNotificacionesTrabajador().subscribe({
      next: (configuracionNotificaciones) => {
        configuracionNotificaciones.forEach((configuracionNotificacion, index) => {
          if (!this._informacionTrabajador.es_responsable_equipo && configuracionNotificacion.id === 6) {
            configuracionNotificaciones.splice(index, 1);
          }
        });
        this.configuracionNotificaciones = configuracionNotificaciones;
        this.fillForm();
      }
    });
  }

  public getConfiguracionNotificacionesFormArray() {
    return this.configuracionNotificacionesForm.get('configuracion_notificaciones') as UntypedFormArray;
  }

  public getFormatosNotificacionFormArray(form: AbstractControl) {
    return form.get('formatos_notificacion') as UntypedFormArray;
  }

  public changeConfiguracionNotificacion(tipoNotificacionId: number, formatoNotificacionId: any, activoToggle: any) {
    const data = {
      tipo_notificacion: tipoNotificacionId,
      formato_notificacion: formatoNotificacionId,
      activo: activoToggle
    };

    this.configuracionUsuarioService.actualizarConfiguracionNotificacionTrabajador(data).subscribe(result => {
    });
  }

  private buildForm() {
    this.configuracionNotificacionesForm = this.formBuilder.group({
      configuracion_notificaciones: this.formBuilder.array([])
    });
  }

  private fillForm() {
    this.configuracionNotificaciones.forEach((configuracionNotificacion) => {
      this.addConfiguracionNotificacionesFormArray(configuracionNotificacion);
    });
  }

  private addConfiguracionNotificacionesFormArray(configuracionNotificaciones: ConfiguracionNotificacion) {

    const configuracionNotificacionesFormArray = this.configuracionNotificacionesForm.get('configuracion_notificaciones') as UntypedFormArray;
    const formGroup = this.formBuilder.group({
      id: configuracionNotificaciones.id,
      descripcion: configuracionNotificaciones.descripcion,
      formatos_notificacion: this.formBuilder.array([])
    });

    configuracionNotificacionesFormArray.push(formGroup);

    const formatosNotificacionFormArray = formGroup.get('formatos_notificacion') as UntypedFormArray;

    configuracionNotificaciones.formatos_notificacion.forEach(formatoNotificacion => {
      let activo: boolean;
      let visible: boolean = true;
      let disabled: boolean = false;
      if (formatoNotificacion.descripcion === 'Email') {
        activo = this._usuarioUsaEmailValido ? formatoNotificacion.activo : false;
        visible = this._usuarioUsaEmailValido;
        disabled = !this._usuarioUsaEmailValido;
      } else {
        activo = formatoNotificacion.activo;
      }
      const formFormatoNotificacionesGroup = this.formBuilder.group({
        id: formatoNotificacion.id,
        descripcion: formatoNotificacion.descripcion,
        descripcion_visible: formatoNotificacion.descripcion_visible,
        activo: new UntypedFormControl({value: activo, disabled: disabled}),
        visible: visible
      });
      formatosNotificacionFormArray.push(formFormatoNotificacionesGroup);
    });


  }
}
