import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ModalRenovarLicenciaComponent} from './modal-renovar-licencia.component';
import {TranslateModule} from '@ngx-translate/core';
import {
  FormularioInformacionFacturacionModule
} from '../formulario-informacion-facturacion/formulario-informacion-facturacion.module';
import {MetodoDePagoModule} from '../metodo-de-pago/metodo-de-pago.module';
import {DirectivesModule} from '../../../../infraestructure/directives/directives.module';


@NgModule({
  declarations: [
    ModalRenovarLicenciaComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    FormularioInformacionFacturacionModule,
    MetodoDePagoModule,
    DirectivesModule
  ],
  exports: [
    ModalRenovarLicenciaComponent
  ]
})
export class ModalRenovarLicenciaModule {
}
