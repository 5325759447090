<div [formGroup]="informacionFacturacionForm">
  <div class="row border-bottom pb-3">
    <ng-container *ngIf="mostrarErroresCabecera && erroresCabecera.length > 0">
      <div class="alert alert-danger margin-errors">
        <ul>
          <li *ngFor="let error of erroresCabecera">
            {{ error }}
          </li>
        </ul>
      </div>
    </ng-container>
    <div class="font-weight-500 text-18 mb-2">{{ 'Datos de la empresa' | translate }}</div>
    <div class="col-lg-7 my-2">
      <label class="form-label" for="informacion_facturacion_nombre_empresa">{{ 'Nombre empresa' | translate }}</label>
      <input
        [ngClass]="{'is-invalid': informacionFacturacionForm.get('informacion_facturacion_nombre_empresa')['errors']}"
        class="input-alf"
        formControlName="informacion_facturacion_nombre_empresa" id="informacion_facturacion_nombre_empresa"
        type="text">
      <small
        *ngIf="informacionFacturacionForm.get('informacion_facturacion_nombre_empresa')['errors'] && !mostrarErroresCabecera"
        class="text-danger">
        {{ informacionFacturacionForm.get('informacion_facturacion_nombre_empresa').errors[0] }}
      </small>
    </div>
    <div class="col-lg-5 my-2 mt-2">
      <label class="form-label" for="informacion_facturacion_cif">{{ 'NIF/CIF' | translate }}</label>
      <input [ngClass]="{'is-invalid': informacionFacturacionForm.get('informacion_facturacion_cif')['errors']}"
             class="input-alf"
             formControlName="informacion_facturacion_cif" id="informacion_facturacion_cif"
             type="text">
      <small *ngIf="informacionFacturacionForm.get('informacion_facturacion_cif')['errors'] && !mostrarErroresCabecera"
             class="text-danger">
        {{ informacionFacturacionForm.get('informacion_facturacion_cif').errors[0] }}
      </small>
    </div>
    <div class="col-lg-12 my-2 mt-2">
      <label class="form-label" for="informacion_facturacion_direccion">{{ 'Dirección' | translate }}</label>
      <input [ngClass]="{'is-invalid': informacionFacturacionForm.get('informacion_facturacion_direccion')['errors']}"
             class="input-alf"
             formControlName="informacion_facturacion_direccion" id="informacion_facturacion_direccion"
             type="text">
      <small
        *ngIf="informacionFacturacionForm.get('informacion_facturacion_direccion')['errors'] && !mostrarErroresCabecera"
        class="text-danger">
        {{ informacionFacturacionForm.get('informacion_facturacion_direccion').errors[0] }}
      </small>
    </div>
    <div class="col-lg-3 my-2 mt-2">
      <label class="form-label" for="informacion_facturacion_ciudad">{{ 'Ciudad' | translate }}</label>
      <input [ngClass]="{'is-invalid': informacionFacturacionForm.get('informacion_facturacion_ciudad')['errors']}"
             class="input-alf"
             formControlName="informacion_facturacion_ciudad" id="informacion_facturacion_ciudad"
             type="text">
      <small
        *ngIf="informacionFacturacionForm.get('informacion_facturacion_ciudad')['errors'] && !mostrarErroresCabecera"
        class="text-danger">
        {{ informacionFacturacionForm.get('informacion_facturacion_ciudad').errors[0] }}
      </small>
    </div>
    <div class="col-lg-3 my-2 mt-2">
      <label class="form-label" for="informacion_facturacion_region">{{ 'Región' | translate }}</label>
      <input [ngClass]="{'is-invalid': informacionFacturacionForm.get('informacion_facturacion_region')['errors']}"
             class="input-alf"
             formControlName="informacion_facturacion_region" id="informacion_facturacion_region"
             type="text">
      <small
        *ngIf="informacionFacturacionForm.get('informacion_facturacion_region')['errors'] && !mostrarErroresCabecera"
        class="text-danger">
        {{ informacionFacturacionForm.get('informacion_facturacion_region').errors[0] }}
      </small>
    </div>
    <div class="col-lg-3 my-2 mt-2">
      <label class="form-label" for="informacion_facturacion_pais">{{ 'País' | translate }}</label>
      <input [ngClass]="{'is-invalid': informacionFacturacionForm.get('informacion_facturacion_pais')['errors']}"
             class="input-alf"
             formControlName="informacion_facturacion_pais" id="informacion_facturacion_pais"
             type="text">
      <small *ngIf="informacionFacturacionForm.get('informacion_facturacion_pais')['errors'] && !mostrarErroresCabecera"
             class="text-danger">
        {{ informacionFacturacionForm.get('informacion_facturacion_pais').errors[0] }}
      </small>
    </div>
    <div class="col-lg-3 my-2 mt-2">
      <label class="form-label" for="informacion_facturacion_codigo_postal">{{ 'C. Postal' | translate }}</label>
      <input
        [ngClass]="{'is-invalid': informacionFacturacionForm.get('informacion_facturacion_codigo_postal')['errors']}"
        class="input-alf"
        formControlName="informacion_facturacion_codigo_postal" id="informacion_facturacion_codigo_postal"
        type="text">
      <small
        *ngIf="informacionFacturacionForm.get('informacion_facturacion_codigo_postal')['errors'] && !mostrarErroresCabecera"
        class="text-danger">
        {{ informacionFacturacionForm.get('informacion_facturacion_codigo_postal').errors[0] }}
      </small>
    </div>
  </div>
  <div class="row mt-3">
    <div class="font-weight-500 text-18 mb-2">{{ 'Datos de contacto' | translate }}</div>
    <div class="col-lg-6 my-2 mt-2">
      <label class="form-label" for="informacion_facturacion_nombre">{{ 'Nombre' | translate }}</label>
      <input [ngClass]="{'is-invalid': informacionFacturacionForm.get('informacion_facturacion_nombre')['errors']}"
             class="input-alf"
             formControlName="informacion_facturacion_nombre" id="informacion_facturacion_nombre"
             type="text">
      <small
        *ngIf="informacionFacturacionForm.get('informacion_facturacion_nombre')['errors'] && !mostrarErroresCabecera"
        class="text-danger">
        {{ informacionFacturacionForm.get('informacion_facturacion_nombre').errors[0] }}
      </small>
    </div>
    <div class="col-lg-6 my-2 mt-2">
      <label class="form-label" for="informacion_facturacion_apellidos">{{ 'Apellidos' | translate }}</label>
      <input [ngClass]="{'is-invalid': informacionFacturacionForm.get('informacion_facturacion_apellidos')['errors']}"
             class="input-alf"
             formControlName="informacion_facturacion_apellidos" id="informacion_facturacion_apellidos"
             type="text">
      <small
        *ngIf="informacionFacturacionForm.get('informacion_facturacion_apellidos')['errors'] && !mostrarErroresCabecera"
        class="text-danger">
        {{ informacionFacturacionForm.get('informacion_facturacion_apellidos').errors[0] }}
      </small>
    </div>
    <div class="col-lg-6 my-2 mt-2">
      <label class="form-label" for="informacion_facturacion_email">{{ 'Email' | translate }}</label>
      <input [ngClass]="{'is-invalid': informacionFacturacionForm.get('informacion_facturacion_email')['errors']}"
             class="input-alf"
             formControlName="informacion_facturacion_email" id="informacion_facturacion_email"
             type="text">
      <small
        *ngIf="informacionFacturacionForm.get('informacion_facturacion_email')['errors'] && !mostrarErroresCabecera"
        class="text-danger">
        {{ informacionFacturacionForm.get('informacion_facturacion_email').errors[0] }}
      </small>
    </div>
    <div class="col-lg-6 my-2 mt-2">
      <label class="form-label" for="informacion_facturacion_telefono">{{ 'Teléfono' | translate }}</label>
      <input [ngClass]="{'is-invalid': informacionFacturacionForm.get('informacion_facturacion_telefono')['errors']}"
             class="input-alf"
             formControlName="informacion_facturacion_telefono" id="informacion_facturacion_telefono"
             type="text">
      <small
        *ngIf="informacionFacturacionForm.get('informacion_facturacion_telefono')['errors'] && !mostrarErroresCabecera"
        class="text-danger">
        {{ informacionFacturacionForm.get('informacion_facturacion_telefono').errors[0] }}
      </small>
    </div>
  </div>
</div>
